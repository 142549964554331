.searchIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.searchIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.searchIcon {
    width: 11px;
    height: 11px;
    /* background-color: red; */
}

.searchIcon > div:first-of-type {
    width: 5px;
    height: 5px;
    border: 1px solid var(--icon-color);
    border-radius: 4px;
}

.searchIconAlt > div:first-of-type {
    border: 1px solid var(--alt-icon-color);
}

.searchIcon > div:last-of-type {
    width: 1px;
    height: 5px;
    margin-top: -2px;
    margin-left: 7px;
    background-color: var(--icon-color);
    transform: rotate(-45deg);
}

.searchIconAlt > div:last-of-type {
    background-color: var(--alt-icon-color);
}