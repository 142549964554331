.bugIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    pointer-events: auto;
    cursor: pointer;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.bugIcon {
    width: 11px;
    height: 11px;
}

.bugHead {
    position: absolute;
    width: 3px;
    height: 3px;
    top: 2.5px;
    left: calc(50% - 4px);
    border-radius: 10px;
    border-top: 1px solid var(--icon-color);
    border-left: 1px solid var(--icon-color);
    transform: rotate(45deg);
}

.bugBody {
    width: 5px;
    height: 7px;
    margin-top: 1px;
    border-radius: 10px;
    border: 1px solid var(--icon-color);
}

.bugLegs>div {
    position: absolute;
    width: 3px;
    height: 1px;
    background-color: var(--icon-color);
}

.bugLegs>div:first-of-type {
    top: 9px;
    left: 12px;
}

.bugLegs>div:nth-of-type(2) {
    top: 9px;
    left: 22px;
}

.bugLegs>div:nth-of-type(3) {
    top: 6px;
    left: 13px;
    transform: rotate(15deg);
}

.bugLegs>div:nth-of-type(4) {
    top: 6px;
    left: 21px;
    transform: rotate(-15deg);
}

.bugLegs>div:nth-of-type(5) {
    top: 12px;
    left: 13px;
    transform: rotate(-15deg);
}

.bugLegs>div:nth-of-type(6) {
    top: 12px;
    left: 21px;
    transform: rotate(15deg);
}