.updaterIconContainer {
    position: fixed;
    /* z-index: 100; */
    bottom: 30px;
    left: 30px;
    width: 19px;
    height: 19px;
    pointer-events: auto;
    /* background-color: blue; */
}

.updaterActive > div:first-of-type {
    width: 1px;
    height: 14px;
    margin-top: 1px;
    margin-left: 9px;
    background-color: var(--icon-color);
}

.updaterActive > div:last-of-type {
    position: absolute;
    width: 6px;
    height: 6px;
    top: 1px;
    left: 6px;
    border-bottom: 1px solid var(--icon-color);
    border-left: 1px solid var(--icon-color);
    transform: rotate(135deg);
}

.updaterDown > div:first-of-type {
    width: 1px;
    height: 14px;
    margin-top: 1px;
    margin-left: 9px;
    background-color: var(--icon-color);
}

.updaterDown > div:last-of-type {
    position: absolute;
    width: 6px;
    height: 6px;
    top: 8px;
    left: 6px;
    border-bottom: 1px solid var(--icon-color);
    border-left: 1px solid var(--icon-color);
    transform: rotate(-45deg);
}

.updaterNone > div:first-of-type {
    width: 1px;
    height: 14px;
    margin-top: 2px;
    margin-left: 9px;
    background-color: var(--icon-color);
    transform: rotate(-45deg);
}

.updaterNone > div:last-of-type {
    position: absolute;
    width: 1px;
    height: 14px;
    top: 2px;
    left: 9px;
    background-color: var(--icon-color);
    transform: rotate(45deg);
}

.updaterIconContainer > p {
    position: absolute;
    width: max-content;
    top: -2px;
    left: 14px;
}