@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

#login {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    color: var(--text-color);
    font-size: 12px;
    /* opacity: 0; */
    /* animation: fadeIn 2s ease-in-out forwards;
    animation-delay: 1.1s; */
    background-color: #0058efa0;
    background: radial-gradient(#0058efa0, black);
}

#login>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
}

@keyframes glow {
    0% {
        transform: translateY(0px);
        filter: drop-shadow(0px 0px 1px white)
    }

    50% {
        transform: translateY(-5px);
        filter: drop-shadow(0px 0px 8px white)
    }

    100% {
        transform: translateY(0px);
        filter: drop-shadow(0px 0px 1px white)
    }
}

/* #login>div>img {
    width: 420px;
    margin-left: 40px;
    margin-bottom: 30px;
    animation: glow 5s ease-in-out infinite;
} */

#login>div>img {
    width: 180px;
    margin-top: 20vh;
    margin-left: 10px;
    margin-bottom: 20px;
    animation: glow 5s linear infinite;
}

/* 
#login>div>img {
    width: 180px;
    margin-bottom: 30px;
    animation: glow 5s ease-in-out infinite;
} */

#pilotLogo {
    color: rgba(255, 255, 255, 0.9);
    font-size: 70px;
    /* font-weight: bold; */
    transform: scaleX(1.1) scaleY(0.5);
    /* -webkit-text-stroke: 2px rgba(0, 0, 0, 0.2); */
}

#pilotMessage {
    /* position: absolute; */
    bottom: 0;
    color: rgba(255, 255, 255, 0.9);
    font-size: 12px;
    margin-bottom: 50px;
    /* transform: scaleX(1.1) scaleY(0.5); */
}

#pilotOptions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 15vh;
    /* background-color: lime; */
}

#pilotOptions>p {
    margin: 30px 0px 20px 0px;
    color: white;
}

#fileTreeButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 350px;
    height: 180px;
    padding: 20px 0px 10px 0px;
    border-radius: 20px;
    border: 1px solid white;
    border-top: 0px solid white;
}

#fileTreeButtonContainer>img {
    position: absolute;
    top: -15px;
    width: 45px;
}

#fileTreeButtonContainer>a {
    width: 200px;
    height: 40px !important;
    /* margin-right: 50px; */
    /* padding: 5px 15px; */
    border-radius: 20px;
    color: white;
    pointer-events: auto;
    cursor: pointer;
    background-color: #ffffff40;
}

#pilotOptions>div>a {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    height: 100%; */
    color: white;
    text-decoration: none;
}

#loginButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loginButtonContainer>input {
    margin-bottom: 20px;
    color: var(--alt-text-color);
    font-size: 12px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    background-color: transparent;
    padding: 5px 25px;
}

.loginButton {
    margin-top: 20px;
    padding: 5px 15px;
    border-radius: 20px;
    color: white;
    pointer-events: auto;
    cursor: pointer;
    background-color: #ffffff40;

}

.loginButton:first-of-type {
    margin-bottom: 10px;
}

#loggingInContainer {
    display: flex;
    margin-left: 20px;
}

#accessDenied {
    color: var(--alt-text-color);
}

#compassLogo {
    position: absolute;
    width: 110px;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    /* margin: 30px 0px 0px 0px; */
}

#compassOptions {
    display: flex;
    justify-content: center;
    position: relative;
    width: 350px;
    /* margin-left: 50px; */
    padding: 20px 0px 10px 0px;
    border-radius: 20px;
    border: 1px solid white;
    border-top: 0px solid white;
}

#compassOptions>div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;
    margin: 10px;
    color: white;
    /* background-color: lime; */
}

#compassOptions>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: 20px;
    color: white;
    pointer-events: auto;
    cursor: pointer;
    background-color: #ffffff40;
}

#compassOptions>div>div>a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    text-decoration: none;
    /* background-color: lime; */
}

#compassOptions>div:first-of-type {
    margin-bottom: 20px;
}

#loggedInUser {
    position: absolute;
    top: -8px;
    right: 20px;
}

/* @media (orientation: landscape) {

    #login>div {
        width: 60vh;
        height: 80vh;
    }

}

@media (orientation: portrait) {

    #login>div {
        width: 60vw;
        height: 80vw;
    }

} */