#maximizeIconContainer {
    padding: 2px 15px;
    border-right: 1px solid var(--alt-border-color);
    pointer-events: auto;
    cursor: pointer;
}

#maximizeIcon {
    width: 10px;
    height: 10px;
    /* background-color: blue; */
}

#maximizeIcon > div:first-of-type {
    width: 3px;
    height: 3px;
}

#maximizeIcon > div:last-of-type {
    width: 3px;
    height: 3px;
    margin-left: 6px;
    margin-top: 2px;
}

.topLeft {
    border-top: 1px solid var(--alt-icon-color);
    border-left: 1px solid var(--alt-icon-color);
}

.bottomRight {
    border-bottom: 1px solid var(--alt-icon-color);
    border-right: 1px solid var(--alt-icon-color);
}