#inspector {
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    z-index: 99;
    width: 35vw;
    max-height: 100vh;
    top: 0;
    right: 0;
    padding: 0px 0px 10px 0px;
    color: white;
    font-size: 12px;
    pointer-events: auto;
}

#inspector::-webkit-scrollbar {
    display: none;
}

#inspector > div {
    width: calc(100% - 24px);
    margin-bottom: 30px;
    padding: 0px 2px;
    /* border: 1px solid var(--border-color); */
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    /* background-color: #0058efa0; */
    background-color: var(--alt-dash-background-color);
    /* background-color: rgba(255, 255, 255, 0.2); */
    /* box-shadow: 15px -15px 15px 2px rgba(0, 0, 0, 0.05); */
    /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15); */
    backdrop-filter: blur(2px);
    transition: height 0.3s ease-in-out;
}

#inspector > div:first-of-type {
    margin-top: 60px;
}

#inspector > div > p {
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 10px;
}