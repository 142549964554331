#ortusTechnologyLogo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
}

#ortusTechnologyLogo>div {
    display: flex;
    align-items: center;
}

#ortusTechnologyLogo>p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1px;
    color: lightgrey;
    margin-bottom: 30px;
}

#by {
    font-size: 8px !important;
    margin-bottom: 10px !important;
}

#ortusTechnologyLogo>div>img {
    width: 80px;
    margin-right: 10px;
}

#ortusTechnologyLogo>div>p {
    display: flex;
    align-items: center;
    height: 18px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 1px;
    color: lightgrey;
    padding-left: 8px;
    border-left: 1px solid #e99a44;
}