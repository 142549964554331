.settingsIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.settingsIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.settingsIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 10px;
    height: 9px;
}

.settingsIcon > div {
    width: 10px;
    height: 1px;
    background-color: var(--icon-color);
}

.settingsIconAlt > div {
    background-color: var(--alt-icon-color);
}