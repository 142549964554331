.usersIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.usersIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.usersIcon {
    position: relative;
    width: 11px;
    height: 11px;
    /* background-color: red; */
}

.usersIcon > div:first-of-type {
    width: 3px;
    height: 3px;
    /* margin-top: 1px; */
    margin-left: 3px;
    border: 1px solid var(--icon-color);
    border-radius: 2px;
}

.usersIconAlt > div:first-of-type {
    border: 1px solid var(--alt-icon-color);
}

.usersIcon > div:last-of-type {
    width: 7px;
    height: 5px;
    margin-left: 1px;
    margin-top: 0px;
    border-top: 1px solid var(--icon-color);
    border-left: 1px solid var(--icon-color);
    border-right: 1px solid var(--icon-color);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.usersIconAlt > div:last-of-type {
    border-top: 1px solid var(--alt-icon-color);
    border-left: 1px solid var(--alt-icon-color);
    border-right: 1px solid var(--alt-icon-color);
}

.label {
    position: absolute;
    top: 100%;
    margin: 0 auto;
    font-size: 10px !important;
    border-left: 0px solid white !important;
    background-color: transparent !important;
}