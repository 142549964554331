.avatarIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    border: 1px solid var(--alt-icon-color);
    border-radius: 5px;
    background-color: var(--dash-background-color);
    margin: 0px 0px 0px 8px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.online {
    box-shadow: 0px 0px 10px var(--user-online-color);
}

.avatarIcon {
    width: 26px;
    height: 25px;
    padding: 2px;
    /* background-color: red; */
}

.avatarIcon > div:first-of-type {
    width: 10px;
    height: 10px;
    /* margin-top: 1px; */
    margin-left: 7px;
    border: 1px solid var(--alt-icon-color);
    border-radius: 10px;
}

.avatarIcon > div:last-of-type {
    width: 16px;
    height: 10px;
    margin-left: 4px;
    margin-top: 0px;
    border-top: 1px solid var(--alt-icon-color);
    border-left: 1px solid var(--alt-icon-color);
    border-right: 1px solid var(--alt-icon-color);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}