.moveComponentIconContainer {
    padding: 2px 15px;
    border-right: 1px solid var(--alt-border-color);
    pointer-events: auto;
    cursor: grab;
}

.moveComponentIcon {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 12px;
    height: 12px;
    pointer-events: none;
}

.moveComponentIcon > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4px;
    height: 4px;
    pointer-events: none;
}

.moveComponentIcon > div > div {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background-color: var(--alt-icon-color);
    pointer-events: none;
}

