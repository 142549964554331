.portfolioIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    pointer-events: auto;
    cursor: pointer;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.portfolioIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 11px;
    height: 11px;
    /* background-color: lime; */
}

.portfolioIconTree {
    height: 8px;
    width: 1px;
    margin-top: 1px;
    background-color: black;
}

.portfolioIconTree:nth-of-type(2) {
    height: 11px;
    width: 1px;
    margin-top: 0px;
    background-color: black;
}

.portfolioIconTree>div {
    position: absolute;
    width: 2px;
    height: 1px;
    background-color: black;
}

.portfolioIconTree>div:nth-of-type(1) {
    width: 2px;
    height: 1px;
    margin-top: 2px;
    margin-left: 0.5px;
    background-color: black;
    transform: rotate(-35deg);
}

.portfolioIconTree>div:nth-of-type(2) {
    width: 2px;
    height: 1px;
    margin-top: 4px;
    margin-left: -1.5px;
    background-color: black;
    transform: rotate(35deg);
}

.portfolioIconBigTree {
    width: 3px !important;
}

.portfolioIconBigTree:nth-of-type(2) {
    margin-top: 5.5px !important;
    margin-left: -2.5px !important;
}