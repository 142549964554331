#settings {
    height: 300px;
    opacity: 1;
    overflow: scroll;
    pointer-events: none;
    transition: height 0.3s ease-in-out;
}

#settings::-webkit-scrollbar {
    display: none;
}

#settingsIconContainer {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
    pointer-events: auto;
    cursor: pointer;
}

#settingsIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 10px;
    height: 9px;
}

#settingsStatusBar {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--alt-border-color);
}

#settingsStatusBar>p {
    color: white;
    font-size: 12px;
    font-style: italic;
    padding: 0px 15px;
    border-right: 1px solid var(--alt-border-color);
    pointer-events: auto;
    cursor: pointer;
}

#settingsPanelOptions {
    width: calc(100% - 20px);
    height: calc(100% - 51px);
    margin-left: 10px;
    margin-top: 30px;
    pointer-events: auto;
    overflow: scroll;
}

#settingsPanelOptions::-webkit-scrollbar {
    display: none;
}

.panelOptionsBlock {
    margin-bottom: 10px;
}

.panelOptionSubBlock {
    margin-top: 5px;
}

#settingsPanelOptions p {
    font-size: 12px;
}

.panelOptionHeader {
    position: relative;
    height: 17px;
    padding: 0px 20px 0px 10px;
    color: white;
    font-size: 12px;
    border-radius: 20px;
    pointer-events: auto;
    cursor: pointer;
}

.sub {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 25px;
    padding: 0px;
    border: 0px solid white;
    border-bottom: 1px solid var(--alt-border-color);
    border-radius: 0px;
    text-align: center;
    cursor: auto;
}

.sub:first-of-type {
    margin-top: 0px;
}

.subSub {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
}

.panelOptionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: max-content;
    padding: 3px 5px;
    pointer-events: auto;
}

.panelOptionContainer p {
    color: white;
}

.panelOptionTitle {
    color: white;
    margin-right: 10px;
}

.panelOptionOptions {
    position: absolute;
    z-index: 10;
    margin-top: 7px;
    margin-left: -10px;
    padding: 10px;
    border: 1px solid var(--alt-border-color);
    border-radius: 10px;
    cursor: pointer;
}

.panelOptionOptions p:hover {
    color: rgba(255, 255, 255, 1);
}

.optionSelector {
    width: 6px;
    height: 6px;
    margin-left: 10px;
    border: 1px solid white;
}

.panelOptionCheckBox {
    width: 6px;
    height: 6px;
    border: 1px solid white;
    border-radius: 6px;
    pointer-events: auto;
    cursor: pointer;
}

.minus {
    padding: 0px 5px 0px 5px;
}

.panelOption {
    display: inline-block;
    width: max-content;
    padding: 1px 5px;
    margin: 0px 5px;
    color: rgba(255, 255, 255, 0.4);
    border: 1px solid var(--alt-border-color);
    border-radius: 20px;
    pointer-events: auto;
    cursor: pointer;
    opacity: 0.5;
}

.selected {
    color: rgba(255, 255, 255, 1) !important;
    border: 1px solid var(--alt-border-color) !important;
    opacity: 1;
}