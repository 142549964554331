.mapIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.mapIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.mapIcon {
    width: 9px;
    height: 9px;
    border: 1px solid var(--icon-color);
}

.mapIconAlt {
    border: 1px solid var(--alt-icon-color);
}