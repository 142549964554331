.folderContentsIconContainerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 15px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}

.folderContentsIconContainerStatusBar {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px 0px 2px 9px;
}

.folderContentsIcon {
    width: 11px;
    height: 11px;
}

.folderContentsIcon>div {
    width: 6px;
    height: 8px;
    border-top: 1px solid var(--icon-color);
    border-left: 1px solid var(--icon-color);
}

.folderContentsIcon>.sheet {
    border: 1px solid var(--icon-color);
}

.folderContentsIcon>.sheet>div {
    width: 4px;
    height: 1px;
    margin-top: 1px;
    margin-left: 1px;
    background-color: var(--icon-color);
}

.sheet>div:nth-of-type(3) {
    width: 3px;
}

.folderContentsIcon>div:nth-of-type(2) {
    margin-top: -12px;
    margin-left: -2px;
}

.folderContentsIcon>div:nth-of-type(3) {
    margin-top: -11px;
    margin-left: -4px;
}

.folderContentsIconAlt>div {
    border-top: 1px solid var(--alt-icon-color);
    border-left: 1px solid var(--alt-icon-color);
}

.folderContentsIconAlt>.sheet {
    border: 1px solid var(--alt-icon-color);
}


.folderContentsIconAlt>.sheet>div {
    background-color: var(--alt-icon-color);
}