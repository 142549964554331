#users {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    pointer-events: none;
    /* transform: translateY(10px); */
    transition: height 0.3s ease-in-out;
    /* opacity: 0; */
    overflow: hidden;
}

#usersStatusBar {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--alt-border-color);
    /* background-color: rgba(255, 255, 255, 0.2); */
}

#usersStatusBar > p {
    color: white;
    font-size: 12px;
    font-style: italic;
    padding: 0px 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.noOfUsersContainer {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 12px;
    border-right: 1px solid var(--alt-border-color);
    pointer-events: auto;
}

.noOfUsersContainer>p {
    padding: 0px 15px;
}

.noOfUsersContainer>.label {
    top: 120%;
}

#usersInner {
    width: calc(100% - 20px);
    height: calc(100% - 51px);
    margin: 30px 10px;
    pointer-events: auto;
    overflow: scroll;
    /* background-color: red; */
}

#usersInner::-webkit-scrollbar {
    display: none;
}

.user {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    /* background-color: red; */
}

.user:first-of-type {
    margin-top: 10px;
}

.userInfo {
    /* height: 27px; */
    width: 100%;
    margin-left: 15px;
    border: 1px solid white;
    border-radius: 5px;
    background-color: var(--dash-background-color);
}

.userInfo > p {
    margin: 4px 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--alt-border-color);
}

.userInfoOptions {
    display: flex;
    margin: 5px 10px;
    /* background-color: red; */
}

.userInfo > div > p {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid var(--alt-border-color);
    cursor: pointer;
}

.userInfo > div > p:last-of-type {
    border-right: 0px solid var(--alt-border-color);
}

.accessLevelsContainer {
    margin: 0px 10px;
    /* background-color: red; */
}

.accessOption {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}

.accessOption > div {
    width: 2px;
    height: 2px;
    margin-right: 5px;
    border-radius: 2px;
    border: 1px solid white;
}

.accessOption > p {
    margin-right: 10px;
}