
#background {
    position: absolute;
    z-index: -10;
    width: 150%;
    height: 150vw;
    top: -25vw;
    left: -25vw;
    background-color: white;
    /* background-color: #f0f0f0; */
    /* background-color: #60afda; */
    /* background: linear-gradient(white, blue, blue, blue); */
    /* background: linear-gradient(white, black, black, black); */
    /* background: radial-gradient(ellipse at 50% 100%, black, blue, white); */
    /* background: radial-gradient(ellipse at 50% 0%, rgb(211, 210, 210), rgba(230, 230, 230, 0.3), transparent), radial-gradient(ellipse at 50% 100%, blue, rgba(0, 0, 139, 0.4)); */
    /* background: radial-gradient(ellipse at 50% 0%, rgba(0, 255, 255, 0.666), rgba(230, 230, 230, 0.3), transparent), radial-gradient(ellipse at 50% 100%, #ff1493, rgb(0, 0, 139)); */
    /* background: radial-gradient(ellipse at 50% 10%, rgba(230, 230, 230, 1), transparent), radial-gradient(ellipse at 50% 100%, rgb(86, 86, 90), rgb(0, 0, 0)); */
    /* background: radial-gradient(ellipse at 10% 10%, rgb(0, 0, 0), transparent), radial-gradient(ellipse at 50% 60%, rgb(105, 98, 93), rgba(0, 255, 247, 1)); */
    /* background: radial-gradient(ellipse at 10% 10%, rgb(0, 0, 0), transparent), radial-gradient(ellipse at 50% 60%, rgb(105, 98, 93), rgba(255, 0, 183, 0.746)); */
    /* background: radial-gradient(ellipse at 10% 10%, rgb(0, 0, 0), transparent), radial-gradient(ellipse at 50% 60%, rgb(105, 98, 93), rgba(255, 115, 0, 0.746)); */
}