#mapCanvasContainer {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    border-bottom-left-radius: 10px 10px;
    pointer-events: none;
    transition: height 0.3s ease-in-out;
    overflow: hidden;
    /* transition: none !important; */
}

#mapStatusBar {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    border-top: 1px solid var(--alt-border-color);
}

#mapStatusBar > p {
    color: white;
    font-size: 12px;
    font-style: italic;
    padding: 0px 15px;
    border-right: 1px solid mapIconAlt;
}

#mapCanvasContainerInner {
    height: calc(100% - 47px);
    margin-top: 25px;
}

